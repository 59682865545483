@font-face {
  font-family: 'Futura';
  src: url('../public/fonts/FuturaLTW05-Bold.woff2') format('woff2'),
    url('../public/fonts/FuturaLTW05-Bold.woff') format('woff');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Futura';
  src: url('../public/fonts/FuturaLTW05-BookOblique.woff2') format('woff2'),
    url('../public/fonts/FuturaLTW05-BookOblique.woff') format('woff');
  font-style: oblique;
  font-display: swap;
}

@font-face {
  font-family: 'Futura';
  src: url('../public/fonts/FuturaLTW05-Book.woff2') format('woff2'),
    url('../public/fonts/FuturaLTW05-Book.woff') format('woff');
  font-weight: normal;
  font-display: swap;
}
