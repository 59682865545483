html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Futura', sans-serif;
  background-color: rgb(24, 24, 24);
}

// Debug
// * {
//   background: rgba(255, 255, 255, 0.1);
// }

html {
  font-size: 62.5%;
}

body {
  overflow-x: hidden;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
  text-transform: none;
}
*:focus:not(:focus-visible) {
  /* Remove focus indication when a mouse is used */
  outline: none;
}
*:focus-visible {
  outline: var(--focus-outline);
  outline-offset: var(--focus-outline-offset);
  outline-color: var(--color-focus-dark);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.swiper {
  overflow: visible;
}

.grecaptcha-badge {
  visibility: hidden;
}
