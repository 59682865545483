:root {
  --color-orange: #dd3800;
  --color-pink: #de005d;
  --color-purple: #6d2b9a;
  --color-blue: #0a77d4;
  --color-green: #008574;
  --color-focus-dark: #fcfcfc;
  --color-focus-white: #076fc9;

  /* greyscale */
  --color-white: #fff;
  --color-black: #000;
  --color-grey-10: #fcfcfc;
  --color-grey-40: #f6f6f6;
  --color-grey-100: #e9e9e9;
  --color-grey-200: #d9d9d9;
  --color-grey-300: #c4c4c4;
  --color-grey-500: #7b7b7b;
  --color-grey-700: #434343;
  --color-black-750: #2a2a2a;
  --color-black-800: #181818;
  --color-black-900: #000;

  --color-grey-40-015: rgba(246, 246, 246, 0.15);

  /* buttons */
  --button-padding: 1.4rem 2.8rem;
  --button-padding-with-icon: 1.2rem 2.6rem 1.2rem 2.4rem;

  @media (min-width: $breakpoint-ipad2) {
    --button-padding: 1.6rem 3.2rem;
    --button-padding-with-icon: 1.6rem 3.4rem 1.6rem 3.2rem;
  }

  /* margins */

  --module-bottom-margin: 4rem;
  --container-top-padding: 2rem;

  --vertScrollerIndicatorTrackHeight: 70px;

  @media (min-width: $breakpoint-m) {
    --module-bottom-margin: 8rem;
    --container-top-padding: 4rem;
  }

  @media (min-width: $breakpoint-ml) {
    --module-bottom-margin: 12rem;
    --container-top-padding: 8rem;
  }

  /* text shadows */

  --text-over-image-shadow: 0px 0px 40px rgba(0, 0, 0, 0.65);

  /* drop shadows */

  --drop-shadow-on-black: drop-shadow(33px 38px 100px rgba(0, 0, 0, 0.3))
    drop-shadow(-40px -42px 100px rgba(255, 255, 255, 0.05));

  /* gradients */
  --gradient-purple-blue: linear-gradient(
    114.31deg,
    #076fc9 -39.46%,
    #6d2b9a 83.83%
  );
  --gradient-purple-blue-hover: linear-gradient(
    54.31deg,
    #076fc9 -24.46%,
    #6d2b9a 123.83%
  );

  --gradient-pink-blue: linear-gradient(
    119.69deg,
    #de005d 14.73%,
    #076fc9 103.38%
  );
  --gradient-pink-blue-hover: linear-gradient(
    94.24deg,
    #de005d 10.19%,
    #0d7ede 118.98%
  );

  --gradient-pink-orange: linear-gradient(
    118.57deg,
    #de005d 8.75%,
    #d73600 110.67%
  );
  --gradient-pink-orange-hover: linear-gradient(
    94.24deg,
    #de005d 10.19%,
    #d73600 198.98%
  );

  --gradient-pink-purple: linear-gradient(
    109.26deg,
    #de005d 2.77%,
    #6d2b9a 95.54%
  );
  --gradient-pink-purple-hover: linear-gradient(
    94.24deg,
    #de005d 10.19%,
    #6d2b9a 198.98%
  );

  --gradient-blue-purple: linear-gradient(
    114.31deg,
    #076fc9 -39.46%,
    #6d2b9a 83.83%
  );

  --gradient-blue-green: linear-gradient(
    114.31deg,
    #076fc9 -39.46%,
    #007d6d 83.83%
  );
  --gradient-blue-green-hover: linear-gradient(
    94.24deg,
    #076fc9 10.19%,
    #007d6d 198.98%
  );

  --gradient-material-button-purple-blue: var(--gradient-purple-blue),
    linear-gradient(
      113.59deg,
      rgba(255, 255, 255, 0.69) 7.33%,
      rgba(255, 243, 248, 0.5) 33.87%,
      rgba(255, 255, 255, 0) 50.55%,
      rgba(255, 255, 255, 0.15) 72.97%,
      rgba(255, 255, 255, 0.5) 99.02%
    );

  --gradient-material-button-pink-orange: var(--gradient-pink-orange),
    linear-gradient(
      113.59deg,
      rgba(255, 255, 255, 0.69) 7.33%,
      rgba(255, 243, 248, 0.5) 33.87%,
      rgba(255, 255, 255, 0) 50.55%,
      rgba(255, 255, 255, 0.15) 72.97%,
      rgba(255, 255, 255, 0.5) 99.02%
    );

  --gradient-material-button-pink-blue: var(--gradient-pink-blue),
    linear-gradient(
      113.59deg,
      rgba(255, 255, 255, 0.69) 7.33%,
      rgba(255, 243, 248, 0.5) 33.87%,
      rgba(255, 255, 255, 0) 50.55%,
      rgba(255, 255, 255, 0.15) 72.97%,
      rgba(255, 255, 255, 0.5) 99.02%
    );

  --gradient-material-button-pink-purple: var(--gradient-pink-purple),
    linear-gradient(
      113.59deg,
      rgba(255, 255, 255, 0.69) 7.33%,
      rgba(255, 243, 248, 0.5) 33.87%,
      rgba(255, 255, 255, 0) 50.55%,
      rgba(255, 255, 255, 0.15) 72.97%,
      rgba(255, 255, 255, 0.5) 99.02%
    );

  --gradient-material-button-blue-green: var(--gradient-blue-green),
    linear-gradient(
      113.59deg,
      rgba(255, 255, 255, 0.69) 7.33%,
      rgba(255, 243, 248, 0.5) 33.87%,
      rgba(255, 255, 255, 0) 50.55%,
      rgba(255, 255, 255, 0.15) 72.97%,
      rgba(255, 255, 255, 0.5) 99.02%
    );

  --gradient-line-purple-blue: linear-gradient(
    to right,
    var(--color-purple) 0%,
    var(--color-blue)
  );

  --focus-color: var(--color-blue);
  --focus-outline: 0.3rem solid var(--focus-color);
  --focus-outline-offset: 0rem;

  --transition: 0.2s ease-in-out;
  --transition-slow: 0.4s ease-in-out;
  --transition-fast: 0.1s ease-in-out;

  /* glass */
  --background-glass-white: linear-gradient(
    116.58deg,
    rgba(255, 255, 255, 0.648) 15.41%,
    rgba(203, 203, 203, 0.536) 122.73%
  );
  --box-shadow-glass-white: 3px 32px 60px rgba(40, 15, 26, 0.1);
  --backdrop-filter-glass-white: blur(10px);

  --background-glass-black: linear-gradient(
    119.68deg,
    rgba(67, 67, 67, 0.9) 14.73%,
    rgba(24, 24, 24, 0.5) 111%
  );
  --box-shadow-glass-black: 0px 4px 100px rgba(0, 0, 0, 0.25);
  --backdrop-filter-glass-black: blur(10px);

  --box-shadow-RichTextComponents: 0 4px 40px 0 rgba(0, 0, 0, 0.04);

  --spacing-xs: 4px;
  --spacing-s: 8px;
  --spacing-m: 12px;
  --spacing-l: 16px;
  --spacing-xl: 24px;
  --spacing-xxl: 32px;
  --spacing-xxxl: 48px;

  //Spacings in rem
  --spacing-16: 1.6rem;
  --spacing-32: 3.2rem;
  --spacing-40: 4rem;
  --spacing-48: 4.8rem;

  --max-width-ss: 345px;
  --max-width-s: 500px;
  --max-width-m: 720px;
  --max-width-ml: 920px;
  --max-width-l: 1260px;
  --max-width-xl: 1440px;
  --max-width-xxl: 1680px;

  --font-family-futura: 'Futura', -apple-system, BlinkMacSystemFont, Segoe UI;

  /***
    fonts
    ***/
  /* mobie: xs - ml
   tablet: ml - ipad2
   desktop: ipad2 - xxl */
  --font-size-body-1: 1.8rem;
  --font-size-body-2: 1.6rem;
  --font-size-body-3: 1.2rem; // img cap
  --font-size-body-4: 1rem; // bread crumb
  --font-size-navigation-titles: 1.7rem;
  --font-size-button-1: 1.8rem;
  --font-size-button-2: 1.6rem;
  --font-size-button-3: 1.4rem;
  --font-size-paragraph: 1.6rem;
  --font-size-caption: 1.4rem;

  --font-size-hero-text-l: 1.6rem;
  --font-size-hero-title: 4rem;
  --font-size-module-title: 3.6rem;
  --font-size-section-title: 3.2rem;
  --font-size-focus-title: 2.8rem;
  --font-size-card-title: 2rem;
  --font-size-rich-card-title: 2rem;
  --font-size-subtitle: 1.6rem;
  --font-size-micro-title: 1.6rem;
  --font-size-preamble: 2rem;
  --font-size-quote: 1.8rem;
  --font-size-quote-title: 1.2rem;
  --font-size-graph-value: 1.6rem;
  --font-size-label-1: 1.6rem;
  --font-size-label-2: 1.2rem;
  --font-size-image-credit: 1rem;

  --font-size-h2: 2.6rem;
  --font-size-h3: 2.1rem;
  --font-size-h4: 1.7rem;
  --font-size-h5: 1.4rem;

  --font-lineheight-p: 1.65;
  --font-lineheight-h2: 1.32;
  --font-lineheight-h3: 1.32;
  --font-lineheight-h4: 1.32;
  --font-lineheight-h5: 1.32;

  @media (min-width: $breakpoint-m) {
    --font-size-caption: 1.6rem;
  }
  @media (min-width: $breakpoint-ml) {
    --font-size-hero-title: 6rem;
    --font-size-module-title: 5.2rem;
    --font-size-section-title: 4.4rem;
    --font-size-focus-title: 3.2rem;
    --font-size-card-title: 2.6rem;
    --font-size-rich-card-title: 2.4rem;
    --font-size-subtitle: 2.2rem;
    --font-size-micro-title: 1.6rem;
    --font-size-quote: 2.2rem;
  }

  @media (min-width: $breakpoint-ipad2) {
    --font-size-body-1: 1.8rem;
    --font-size-body-2: 1.6rem;
    --font-size-body-3: 1.2rem; // img cap
    --font-size-body-4: 1rem; // bread crumb
    --font-size-paragraph: 1.8rem;
    --font-size-navigation-titles: 1.7rem;
    --font-size-button-1: 1.8rem;
    --font-size-button-2: 1.6rem;
    --font-size-button-3: 1.4rem;

    --font-size-hero-text-l: 2.2rem;
    --font-size-hero-title: 8.4rem;
    --font-size-module-title: 6.8rem;
    --font-size-section-title: 5.4rem;
    --font-size-focus-title: 4rem;
    --font-size-card-title: 3.2rem;
    --font-size-rich-card-title: 2.8rem;
    --font-size-subtitle: 2.4rem;
    --font-size-micro-title: 1.8rem;
    --font-size-preamble: 2.2rem;
    --font-size-quote: 2.6rem;
    --font-size-quote-title: 1.5rem;
    --font-size-graph-value: 1.8rem;
    --font-size-label-1: 1.6rem;
    --font-size-label-2: 1.4rem;

    --font-size-h2: 3.5rem;
    --font-size-h3: 2.4rem;
    --font-size-h4: 1.9rem;
    --font-size-h5: 1.6rem;

    --font-lineheight-p: 1.6;
    --font-lineheight-h2: 1.31;
    --font-lineheight-h3: 1.38;
    --font-lineheight-h4: 1.32;
    --font-lineheight-h5: 1.32;
  }

  /* To be removed */
  --font-size-navigation-titles: 1.7rem;
  --font-size-h3: 2.4rem;
  --font-size-body-2: 1.6rem;
  --font-size-button-1: 1.8rem;
  --font-size-button-2: 1.6rem;

  /* PLACEHOLDERS */
  --font-size-nav-mobile-text: 1.4rem;
  --font-size-nav-mobile-link: 2.3rem;
  --font-size-nav-mobile-back: 1.2rem;
}
