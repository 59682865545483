@use 'sass:math';

.container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 var(--spacing-l);
}

/** not possible to use css variables in media queries **/

@media (min-width: $breakpoint-s) {
  .container {
    padding: 0 16px;
  }
}

@media (min-width: $breakpoint-m) {
  .container {
    max-width: var(--max-width-m);
    padding: 0;
  }
}

@media (min-width: $breakpoint-ml) {
  .container {
    max-width: var(--max-width-ml);
    padding: 0;
  }
}

@media (min-width: $breakpoint-l) {
  .container {
    max-width: var(--max-width-l);
    padding: 0;
  }
}

@media (min-width: $breakpoint-xl) {
  .container {
    max-width: var(--max-width-xl);
    padding: 0;
  }
}

/* Can't get this to work with css vars */

$breakpoint: 46em;

.grid {
  $container-width: 440px;
  $columns: 4;
  $gap: 16px;
  $column-width: math.div($container-width, $columns) - $gap;
  display: grid;
  grid-gap: $gap;
  grid-template-columns: minmax(1px, 1fr) repeat(4, minmax(0, $column-width)) minmax(
      1px,
      1fr
    );

  @media (min-width: $breakpoint-m) {
    $container-width: 728px;
    $columns: 12;
    $gap: 24px;
    $column-width: math.div($container-width, $columns) - $gap;
    grid-gap: $gap;
    grid-template-columns:
      minmax($gap, 1fr) repeat($columns, minmax(0, 42px))
      minmax($gap, 1fr);
  }

  @media (min-width: $breakpoint-m2) {
    $container-width: 900px;
    $columns: 12;
    $gap: 24px;
    $column-width: math.div($container-width, $columns) - $gap;
    grid-gap: $gap;
    grid-template-columns:
      minmax($gap, 1fr) repeat($columns, minmax(0, 42px))
      minmax($gap, 1fr);
  }

  @media (min-width: $breakpoint-ml) {
    $container-width: 1024px;
    $columns: 12;
    $gap: 24px;
    $column-width: math.div($container-width, $columns) - $gap;
    grid-gap: $gap;
    grid-template-columns:
      minmax($gap, 1fr) repeat($columns, minmax(0, $column-width))
      minmax($gap, 1fr);
  }

  @media (min-width: $breakpoint-ipad) {
    $container-width: 1124px;
    $columns: 12;
    $gap: 24px;
    $column-width: math.div($container-width, $columns) - $gap;
    grid-gap: $gap;
    grid-template-columns:
      minmax($gap, 1fr) repeat($columns, minmax(0, $column-width))
      minmax($gap, 1fr);
  }

  @media (min-width: $breakpoint-ipad2) {
    $container-width: 1224px;
    $columns: 12;
    $gap: 24px;
    $column-width: math.div($container-width, $columns) - $gap;
    grid-gap: $gap;
    grid-template-columns:
      minmax($gap, 1fr) repeat($columns, minmax(0, $column-width))
      minmax($gap, 1fr);
  }

  @media (min-width: $breakpoint-l) {
    $container-width: 1260px;
    $columns: 12;
    $gap: 24px;
    $column-width: math.div($container-width, $columns) - $gap;
    grid-gap: $gap;
    grid-template-columns:
      65px repeat($columns, minmax(0, $container-width))
      65px;
  }

  @media (min-width: $breakpoint-xl) {
    $container-width: 1440px;
    $columns: 12;
    $gap: 24px;
    $column-width: math.div($container-width, $columns) - $gap;
    grid-gap: $gap;
    grid-template-columns:
      minmax($gap, 1fr) repeat($columns, minmax(0, $column-width))
      minmax($gap, 1fr);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes backgroundChange {
  0% {
    background-color: none;
  }
  100% {
    background-color: white;
  }
}
@keyframes backgroundChangeBack {
  0% {
    background-color: white;
  }
  100% {
    background-color: none;
  }
}
.black-glass {
  border: 1px solid;
  border-width: 1px;
  color: var(--color-grey-10);
  background: var(--background-glass-black);
  box-shadow: var(--box-shadow-glass-black);
  backdrop-filter: var(--backdrop-filter-glass-black);
  border-image-source: linear-gradient(
    113.59deg,
    rgba(255, 255, 255, 0.69) 7.33%,
    rgba(255, 243, 248, 0.5) 33.87%,
    rgba(255, 255, 255, 0) 50.55%,
    rgba(255, 255, 255, 0.15) 72.97%,
    rgba(255, 255, 255, 0.5) 99.02%
  );
  border-image-slice: 1;
}

.white-glass {
  border: 1px solid;
  border-width: 1px;
  color: #000;
  background: var(--background-glass-white);
  box-shadow: var(--box-shadow-glass-white);
  backdrop-filter: var(--backdrop-filter-glass-white);
  border-image-source: linear-gradient(
    113.28deg,
    #ffffff 5.69%,
    rgba(255, 243, 248, 0.5) 32.71%,
    rgba(255, 255, 255, 0) 49.68%,
    rgba(255, 255, 255, 0.47) 72.51%,
    #ffffff 99.02%
  );
  border-image-slice: 1;
}
